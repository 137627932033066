$background: #F5ECEC
$white: white
$black: #474747
$black-primary: #251616
$black-action: #160808
$line-primary: #000000
$line-secondary: #E0DCDC

$danger: #FF0000

$button-disabled-fill: #C4C4C4
$button-disabled-text: #8E8E8E
$button-program: #F2F0F0

$online-primary: #00FF19
$online-secondary: #6CFF7B
$offline-primary: #FFC6C6
$offline-secondary: #FFC6C6


$menu-transition: .2s ease-in-out
$menu-transition-long: .4s ease-in-out
$border: 1px solid $line-primary


@function rem($px)
  @return calc(#{$px} / 16 * 1rem)

@function get-breakpoints($key: "md")
  @return map-get($container-max-widths, $key)


$spacers: ( xxs: rem(24), xs: rem(32), s: rem(48), m: rem(64), l: rem(96), xl: rem(128) )


// GRID
$grid-columns: 12

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1368px )

// $grid-gutter-width: .5rem
// $grid-gutter-width: 28 24 24 20
$grid-gutter-width: 24px
$gutters: ( 0: 0, 1: $grid-gutter-width, 2: $grid-gutter-width * 2, 3: $grid-gutter-width * 3, 4: $grid-gutter-width * 4, 5: $grid-gutter-width * 5 )

$container-max-widths: ( xs: 100vw, sm: 540px + $grid-gutter-width, md: 720px + $grid-gutter-width, lg: 960px + $grid-gutter-width, xl: 1304px + $grid-gutter-width )
