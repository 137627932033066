.row
  @include media-breakpoint-down(md)
    @include row-cols(6)
    .col-1
      flex: 0 0 16.6666666667%
      max-width: 16.6666666667%
    .col-sm-1
      @include media-breakpoint-up(sm)
        flex: 0 0 16.6666666667% !important
        max-width: 16.6666666667% !important
    .col-md-1
      @include media-breakpoint-up(md)
        flex: 0 0 16.6666666667% !important
        max-width: 16.6666666667% !important
    .col-2
      flex: 0 0 33.3333333333%
      max-width: 33.3333333333%
    .col-sm-2
      @include media-breakpoint-up(sm)
        flex: 0 0 33.3333333333% !important
        max-width: 33.3333333333% !important
    .col-md-2
      @include media-breakpoint-up(md)
        flex: 0 0 33.3333333333% !important
        max-width: 33.3333333333% !important
    .col-3
      flex: 0 0 50%
      max-width: 50%
    .col-sm-3
      @include media-breakpoint-up(sm)
        flex: 0 0 50% !important
        max-width: 50% !important
    .col-md-3
      @include media-breakpoint-up(md)
        flex: 0 0 50% !important
        max-width: 50% !important
    .col-4
      flex: 0 0 66.6666666667%
      max-width: 66.6666666667%
    .col-sm-4
      @include media-breakpoint-up(sm)
        flex: 0 0 66.6666666667% !important
        max-width: 66.6666666667% !important
    .col-md-4
      @include media-breakpoint-up(md)
        flex: 0 0 66.6666666667% !important
        max-width: 66.6666666667% !important
    .col-5
      flex: 0 0 83.3333333333%
      max-width: 83.3333333333%
    .col-sm-5
      @include media-breakpoint-up(sm)
        flex: 0 0 83.3333333333% !important
        max-width: 83.3333333333% !important
    .col-md-5
      @include media-breakpoint-up(md)
        flex: 0 0 83.3333333333% !important
        max-width: 83.3333333333% !important
    .col-6
      flex: 0 0 100%
      max-width: 100%
    .col-sm-6
      @include media-breakpoint-up(sm)
        flex: 0 0 100% !important
        max-width: 100% !important
    .col-md-6
      @include media-breakpoint-up(md)
        flex: 0 0 100% !important
        max-width: 100% !important
  @include media-breakpoint-down(sm)
    @include row-cols(4)
    .col-1
      flex: 0 0 25%
      max-width: 25%
    .col-sm-1
      @include media-breakpoint-up(sm)
        flex: 0 0 25% !important
        max-width: 25% !important
    .col-md-1
      @include media-breakpoint-up(md)
        flex: 0 0 25% !important
        max-width: 25% !important
    .col-2
      flex: 0 0 50%
      max-width: 50%
    .col-sm-2
      @include media-breakpoint-up(sm)
        flex: 0 0 50% !important
        max-width: 50% !important
    .col-md-2
      @include media-breakpoint-up(md)
        flex: 0 0 50% !important
        max-width: 50% !important
    .col-3
      flex: 0 0 75%
      max-width: 75%
    .col-sm-3
      @include media-breakpoint-up(sm)
        flex: 0 0 75% !important
        max-width: 75% !important
    .col-md-3
      @include media-breakpoint-up(md)
        flex: 0 0 75% !important
        max-width: 75% !important
    .col-4
      flex: 0 0 100%
      max-width: 100%
    .col-sm-4
      @include media-breakpoint-up(sm)
        flex: 0 0 100% !important
        max-width: 100% !important
    .col-md-4
      @include media-breakpoint-up(md)
        flex: 0 0 100% !important
        max-width: 100% !important
