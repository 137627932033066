@import '~bootstrap/scss/bootstrap.scss'

  
.desktop-only
  @include media-breakpoint-down(md)
    display: none !important
.mobile-only
  @include media-breakpoint-up(lg)
    display: none !important

.no-select
  -webkit-touch-callout: none  /* iOS Safari */
  -webkit-user-select: none    /* Safari */
  -khtml-user-select: none     /* Konqueror HTML */
  -moz-user-select: none       /* Firefox */
  -ms-user-select: none        /* Internet Explorer/Edge */
  user-select: none            /* Non-prefixed version, currently supported by Chrome and Opera */

.no-scrollbar
  -ms-overflow-style: none  /* Internet Explorer 10+ */
  scrollbar-width: none  /* Firefox */
  &::-webkit-scrollbar 
    display: none  /* Safari and Chrome */

.abs-cover
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0

.def-img
  background-repeat: no-repeat
  background-position: center
  // background-size: cover
  background-size: contain

.inherit-all
  font-family: inherit
  font-weight: inherit
  font-size: inherit
  color: inherit
  line-height: inherit

.cursor-pointer
  cursor: pointer

.opened-header
  padding-top: rem(76)
  @include media-breakpoint-down(sm)
    padding-top: rem(68)

.d-flex
  display: -webkit-box
  display: -moz-box
  display: -ms-flexbox
  display: -webkit-flex
  display: flex

.flex-grow-1
  -webkit-box-flex: 1
  -moz-box-flex: 1
  -webkit-flex: 1
  -ms-flex: 1
  flex: 1