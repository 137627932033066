@import './vars.sass'
@import './fonts.sass'
@import './elements.sass'
@import './modificators.sass'
@import './row.sass'

@import '~bootstrap/scss/bootstrap.scss'


*
  font-family: 'Formular', sans-serif
  font-display: auto
  color: $black-primary
  margin: 0
  padding: 0
  box-sizing: border-box
  -webkit-font-smoothing: antialiased


.App
  // overflow: hidden
  width: 100%
  height: 100%


.Header__link__item--hover--bottom
  position: relative
  &::before
    content: ''
    z-index: 10000
    position: absolute
    width: 100%
    height: rem(42)
    left: 0
    top: 100%

.Header
  width: 100%
  background-color: $white
  overflow-y: scroll
  @extend .no-scrollbar
  &--fixed
    z-index: 100
    position: fixed
    top: -100%
    left: 0
    transition: top $menu-transition
    &--show
      top: 0
      overflow-y: visible
  border-bottom: 1px solid rgba(0, 0, 0, 0)
  transition: border-bottom $menu-transition-long
  &--secondary
    border-bottom: $border
  &__container
    @extend .container
    @extend .d-flex
    @extend .flex-row
    @extend .align-items-center
    height: rem(112)
    @include media-breakpoint-down(md)
      height: rem(72)
    // height: 100%
  &__burger,
  &__links
    height: rem(48)
    @include media-breakpoint-down(md)
      height: rem(40)
  &__controls
    @extend .d-none
    @extend .d-md-flex
    @extend .flex-row
    margin-left: rem(32)
    @include media-breakpoint-down(md)
      margin-right: rem(60)
    &__item
      @extend .d-flex
      @extend .flex-row
      @extend .align-items-center
      @extend .button
      border: 1px solid $white
      border-radius: rem(20)
      height: rem(32)
      margin-right: rem(8)
      padding: 0 rem(10) 0 rem(6)
      transition: background-color $menu-transition, color $menu-transition
      &, &.Link
        @extend .d-flex
        @extend .flex-row
        @extend .align-items-center
      @include media-breakpoint-down(lg)
        &, &.Link
          font-size: rem(12) !important
          .FormattedMessage
            font-size: rem(12) !important
      &:not(&--user):hover
        background-color: $black-primary
        color: $white
      &--active
        background-color: $black-action !important
        color: $white
        border-color: $white !important
      &--user
        @extend .button--navigation
        cursor: default
        &--hover
          // @extend .button--navigation--hover
          border-color: $line-primary
          @extend .Header__link__item--hover--bottom
      &__icon
        width: rem(20)
        height: rem(20)
        margin-right: rem(4)
      path, circle, line, rect
        transition: stroke $menu-transition
      &:not(&--user):hover &__icon, &--active &__icon
        path, circle, line, rect
          stroke: $white
  &__burger
    width: rem(48)
    height: rem(24)
    @extend .d-lg-none
    @include media-breakpoint-down(md)
      width: rem(40)
    @extend .def-img
    background-image: url(./img/burger.svg)
    cursor: pointer
  &__links
    @extend .d-flex
    @extend .flex-row
    @extend .align-items-center
    @include media-breakpoint-down(md)
      display: none !important
    &__item
      @extend .button--navigation
      font-weight: bold
      margin-left: rem(8)
      &--hover
        @extend .button--navigation--hover
        @extend .Header__link__item--hover--bottom
      &--active
        color: $white
        background-color: $black-primary
        transition: color $menu-transition
        &:hover
          color: $white
          cursor: default
        &::after
          background-image: url(./img/arrow-down-white.svg) !important
      &--dropdown
        cursor: default
        position: relative
        padding-right: rem(45)
        &::after
          content: ''
          position: absolute
          right: rem(15)
          top: 50%
          transform: translateY(-50%)
          width: rem(24)
          height: rem(24)
          @extend .def-img
          background-size: 60% 60%
          background-image: url(./img/arrow-down.svg)     
      &--FestivalPass
        background-color: $online-primary
        color: $black
        // &:hover
        //   color: $white
        //   border: 1px solid transparent
  &__secondary
    position: absolute
    top: rem(113)
    @include media-breakpoint-down(md)
      top: rem(73)
    background-color: $white
    width: 100%
    @extend .d-flex
    @extend .flex-column
    @extend .align-items-center
    @extend .justify-content-center
    height: 0
    overflow-y: hidden
    z-index: 100
    transition: height $menu-transition
    &--show
      height: rem(84)
      @include media-breakpoint-down(md)
        height: rem(76)
      @include media-breakpoint-down(xs)
        height: rem(68)
    .HorizontalShowcase
      background-color: $white
      @extend .py-0
      .Header__links__item
        height: rem(40)
        padding-top: rem(6)
        margin-left: 0
        margin-right: rem(8)
        &:not(&--exit)
          @extend .flex-grow-1
        &--exit
          background-color: rgba(255, 0, 0, .2)
          width: rem(197)
          margin-right: 0
          transition: border-color $menu-transition
          &:hover
            border-color: rgba(43, 28, 28, 0)
          @include media-breakpoint-down(lg)
            width: rem(140)
          @include media-breakpoint-down(md)
            width: rem(100)
          @include media-breakpoint-down(xs)
            width: rem(120)
  &--mobile
    @extend .d-flex
    @extend .flex-column
    background-color: $black-primary
    width: 100vw
    height: 100vh
    position: absolute
    top: 0
    right: 0
    &__container
      @extend .d-block
      @extend .d-lg-none
      position: fixed
      z-index: 20000
      top: 0
      right: 0
      height: 0
      width: 0
      overflow: hidden
      border-bottom-left-radius: rem(1000)
      transition: width $menu-transition, height $menu-transition, border-bottom-left-radius $menu-transition
      &--opened
        width: 100vw
        height: 100vh
        border-bottom-left-radius: rem(0)
    .Header__burger
      background-image: url(./img/Header__cross.svg)
      width: rem(40)
      height: rem(40)
    .LogoWithText
      rect
        fill: $white
      path
        fill: $black-primary
      &__name
        color: $white
    .Header__controls__item
      color: $white !important
      background-color: $black-primary !important
      border-color: $black-primary !important
      cursor: pointer !important
      path, circle, line, rect
        stroke: $white
    &__children
      @extend .container
      @extend .d-flex
      @extend .flex-column
      @extend .flex-grow-1
      @extend .no-scrollbar
      overflow-y: scroll
      overflow-x: hidden
      padding: rem(90) 0 rem(120)
      @include media-breakpoint-down(sm)
        padding: rem(40) 0 rem(120)
        min-height: 150vh
        @media (orientation: landscape)
          min-height: 250vh
      .Header__controls
        @extend .d-flex
        @extend .mr-0
        @extend .justify-content-end
      .Link,
      .FormattedMessage
        color: $white
      .row
        @extend .flex-nowrap
        &:not(&:last-of-type)
          margin-bottom: rem(19)
          .Link:not(.Header__controls__item)
            @extend .h1
            @extend .d-inline-block
            margin-right: rem(40)
        &:last-of-type
          @extend .mt-m
          .Link:not(.Header__controls__item)
            @extend .d-inline-block
            margin-right: rem(20)
            @include media-breakpoint-down(sm)
              margin-right: rem(30)
    .Header__secondary
      @extend .d-none
           


.Footer
  margin-top: rem(128)
  @include media-breakpoint-down(sm)
    margin-top: rem(64)
  &__container
    @extend .container
  &__links,
  &__contacts,
  &__disclaimer
    @extend .row
    position: relative
    &::before
      content: ''
      width: calc(100% - #{$grid-gutter-width})
      height: rem(1)
      background-color: $black
      position: absolute
      top: 0
      left: $grid-gutter-width / 2
  &__contacts,
  &__disclaimer
    margin-top: rem(48)
    @include media-breakpoint-down(md)
      margin-top: rem(25)
    @include media-breakpoint-down(sm)
      margin-top: rem(32)
  &__links
    padding-top: rem(40)
    @include media-breakpoint-down(md)
      padding-top: rem(27)
    @include media-breakpoint-down(sm)
      padding-top: rem(24)
    &__home
      @extend .d-flex
      @extend .flex-row
      @extend .flex-md-column
      @extend .align-items-md-start
      @extend .align-items-center
      &__logo
        width: rem(90)
        height: rem(90)
        margin-bottom: rem(16)
        @include media-breakpoint-down(md)
          width: rem(100)
          height: rem(100)
          margin-bottom: rem(20)
        @include media-breakpoint-down(sm)
          width: rem(48)
          height: rem(48)
          margin-right: rem(16)
          margin-bottom: 0
        @extend .def-img
        background-image: url(./img/logo.svg)
      &__title
        display: inline-block
        font-family: 'Montserrat', sans-serif
        font-variant-alternates: normal
        text-transform: lowercase
        font-size: rem(20)
        @include media-breakpoint-down(md)
          font-size: rem(16.66666)        
    &__1
      @extend .col-lg-4
    &__2
      @extend .col-lg-3
    &__divider
      min-height: rem(20)
      @extend .d-none
      @extend .d-md-block
      @extend .d-lg-none
    &__3
      @extend .col-lg-3
    &__4
      @extend .col-lg-2
    &__1,
    &__2,
    &__divider,
    &__3,
    &__4
      @extend .col-12
      @extend .col-md-2
      @include media-breakpoint-down(md)
        margin-bottom: rem(32)
        &:last-of-type
          margin-bottom: 0
    &__1,
    &__2,
    &__3,
    &__4
      @extend .d-flex
      @extend .flex-column
      @extend .align-items-start

  &__contacts
    padding-top: rem(32)
    @include media-breakpoint-down(md)
      padding-top: rem(19)
    @include media-breakpoint-down(sm)
      padding-top: rem(16)
    &__1,
    &__divider
      @extend .col-lg-4
      @extend .col-md-4
    &__divider
      min-height: rem(20)
      @extend .d-none
      @extend .d-md-block
      @extend .d-lg-none
    &__2,
    &__3
      @extend .col-lg-3
      @extend .col-md-2
    &__4
      @extend .col-lg-2
      @extend .col-md-2
    &__1,
    &__2
      @include media-breakpoint-down(md)
        margin-bottom: rem(24)
      @include media-breakpoint-down(sm)
        margin-bottom: rem(32)
    &__1,
    &__2,
    &__3,
    &__4
      @extend .d-flex
      @extend .flex-column
      @extend .align-items-start
      @extend .col-12
      
  &__disclaimer
    padding-top: rem(16)
    @include media-breakpoint-down(md)
      padding-top: rem(14)
    @include media-breakpoint-down(sm)
      padding-top: rem(16)
    img
      width: 100%
  
  &__link
    &--regular
      @extend .p--m
      text-decoration: underline !important
      display: inline-block
      @extend .mb-2
      min-height: rem(24)
    &--title
      display: inline-block
      text-transform: uppercase
      color: $white
      background-color: $line-primary
      padding: 0 rem(10)
      border-radius: rem(6)
      margin-bottom: rem(16)
      @include font-params(12, 24)
      @include media-breakpoint-down(md)
        margin-bottom: rem(18)
        @include font-params(10, 24)
      @include media-breakpoint-down(sm)
        margin-bottom: rem(8)
    &--email
      @extend .mb-0
      @include font-params(24, 32)
      @include media-breakpoint-down(lg)
        @include font-params(20, 28)
      @include media-breakpoint-down(md)
        @include font-params(20, 32)
      @include media-breakpoint-down(sm)
        @include font-params(20, 32)



.Login
  &__container
    @extend .container
    width: rem(694)
    padding-top: rem(56)
    @include media-breakpoint-down(md)
      width: rem(593)
      padding-top: rem(96)
    @include media-breakpoint-down(xs)
      width: rem(300)
      padding-top: rem(60)
  &__main
    width: 100%
    @extend .d-flex
    @extend .flex-column
    @extend .align-items-center
    @extend .pink-block
    position: relative
    padding: rem(38) rem(87) rem(76)
    @include media-breakpoint-down(md)
      padding: rem(38) rem(60) rem(76)
    @include media-breakpoint-down(xs)
      padding: rem(42) rem(20) rem(52)
    &__back
      @extend .p
      @extend .p--m
      @extend .p--arrow
      @extend .p--arrow--left
      cursor: pointer
      position: absolute
      top: rem(-40)
      left: 0
      @include media-breakpoint-up(md)
        top: rem(20)
        left: rem(20)
    &__h2
      @extend .h2
    &__controls
      width: rem(416)
      @extend .d-flex
      @extend .flex-column
      @include media-breakpoint-down(md)
        width: rem(340)
      @include media-breakpoint-down(xs)
        width: 100%
      &__button
        @extend .button--main--2
  &__small
    @extend .p
    @extend .p--s
    @extend .my-xs
    @extend .mx-4
    color: $button-disabled-text
    white-space: nowrap
  &__input
    @extend .mb-2
    @extend .text-center
    outline: none
    height: rem(40)
    @include font-params(10, 40)
    @include media-breakpoint-up(md)
      height: rem(48)
      @include font-params(16, 48)
    @include media-breakpoint-up(lg)
      height: rem(56)
      @include font-params(18, 56)
    border-radius: rem(12)
    border: 1px solid $line-secondary
    &::placeholder
      color: $button-disabled-text
    &:focus
      border: 1px solid $line-primary
    &--error
      border: 1px solid $danger !important
      &::placeholder
        color: $danger !important




.Tickets,
.Admin,
.Settings,
.ShowPage
  @extend .opened-header
  margin-top: rem(87)
  @include media-breakpoint-down(lg)
    margin-top: rem(57)
  @include media-breakpoint-down(xs)
    margin-top: rem(44)

.Settings
  &__container
    @extend .container
  &__h1
    @extend .h1
    margin-bottom: rem(112)
    @include media-breakpoint-down(md)
      margin-bottom: rem(81)
    @include media-breakpoint-down(xs)
      margin-bottom: rem(53)
  &__registered
    @extend .h3
    @extend .h3--underline
  &__sessions
    @extend .row
    &__item
      @extend .pink-block
      padding: rem(23) rem(24) rem(39)
      @extend .col-12
      @extend .col-sm-6
      @extend .col-md-4
      &__date
        @extend .h3
        @extend .h3--underline
        font-weight: 400
      &__device-info
        @extend .font-spectral





.Home
  &__cover
    width: 100%
    // background-color: #FFDE00
    @extend .mb-s
    height: rem(684)
    @include media-breakpoint-down(lg)
      height: rem(512)
    @include media-breakpoint-down(md)
      height: rem(460)
    @include media-breakpoint-down(sm)
      height: rem(320)
  &__Laba
    background-color: $background
    &__Img
      border-radius: rem(12)
      overflow: hidden
      width: 100%
      height: calc((100vw - #{$grid-gutter-width}) / 300 * 204)
      @include media-breakpoint-up(md)
        height: rem(237)
      @include media-breakpoint-up(lg)
        height: rem(328)
      @include media-breakpoint-up(xl)
        height: rem(430)





.HorizontalShowcase
  width: 100%
  background-color: $white
  &--Home
    background-color: $background
    padding: rem(56) 0 rem(120)
    @include media-breakpoint-down(md)
      padding: rem(62) 0 rem(96)
    @include media-breakpoint-down(md)
      padding: rem(46) 0 rem(64)
  &__scrollbar
    cursor: pointer
    position: absolute
    bottom: rem(-24)
    height: rem(8)
    background-color: $black-primary
    @include media-breakpoint-down(sm)
      display: none
  &__scroll
    width: 100%
    overflow-x: scroll
    @extend .no-scrollbar
    position: relative
    &__container
      @extend .d-inline-flex
      @extend .flex-row
      @extend .flex-nowrap
      padding-left: calc((100vw - #{get-breakpoints('xl')} + #{$grid-gutter-width}) / 2)
      padding-right: calc((100vw - #{get-breakpoints('xl')} + #{$grid-gutter-width}) / 2)
      min-width: 100%
      @include media-breakpoint-down(lg)
        padding-left: calc((100vw - #{get-breakpoints('lg')} + #{$grid-gutter-width}) / 2)
        padding-right: calc((100vw - #{get-breakpoints('lg')} + #{$grid-gutter-width}) / 2)
      @include media-breakpoint-down(md)
        padding-left: calc((100vw - #{get-breakpoints('md')} + #{$grid-gutter-width}) / 2)
        padding-right: calc((100vw - #{get-breakpoints('md')} + #{$grid-gutter-width}) / 2)
      @include media-breakpoint-down(sm)
        padding-left: calc((100vw - #{get-breakpoints('sm')} + #{$grid-gutter-width}) / 2)
        padding-right: calc((100vw - #{get-breakpoints('sm')} + #{$grid-gutter-width}) / 2)
      @include media-breakpoint-down(xs)
        padding-left: calc((100vw - #{get-breakpoints('xs')} + #{$grid-gutter-width}) / 2)
        padding-right: calc((100vw - #{get-breakpoints('xs')} + #{$grid-gutter-width}) / 2)
    &__corner
      cursor: url(./img/arrow-right.svg), auto !important
      *
        cursor: url(./img/arrow-right.svg), auto !important
  &__item
    &--L,
    &--M,
    &--S
      &.HorizontalShowcase__item--moved
        margin-left: calc(-#{get-breakpoints('xs')} / 5 * 4)
        @include media-breakpoint-up(sm)
          margin-left: calc(-#{get-breakpoints('sm')} / 5 * 4)
        @include media-breakpoint-up(md)
          margin-left: calc(-#{get-breakpoints('md')} / 7 * 3)
    &--XXL
      width: calc(#{get-breakpoints('xs')} / 5 * 4.5 - #{$grid-gutter-width})
      @include media-breakpoint-up(sm)
        width: calc(#{get-breakpoints('sm')} / 5 * 4.5 - #{$grid-gutter-width})
      @include media-breakpoint-up(md)
        width: calc(#{get-breakpoints('md')} / 6 * 5 - #{$grid-gutter-width})
      @include media-breakpoint-up(lg)
        width: calc(#{get-breakpoints('lg')} / 12 * 8 - #{$grid-gutter-width})
      @include media-breakpoint-up(xl)
        width: calc(#{get-breakpoints('xl')} / 13 * 9 - #{$grid-gutter-width})
      &.HorizontalShowcase__item--moved
        margin-left: calc(-#{get-breakpoints('xs')} / 5 * 4.5)
        @include media-breakpoint-up(sm)
          margin-left: calc(-#{get-breakpoints('sm')} / 5 * 4.5)
        @include media-breakpoint-up(md)
          margin-left: calc(-#{get-breakpoints('md')} / 6 * 5)
        @include media-breakpoint-up(lg)
          margin-left: calc(-#{get-breakpoints('lg')} / 12 * 8)
        @include media-breakpoint-up(xl)
          margin-left: calc(-#{get-breakpoints('xl')} / 13 * 9)
    &--XL
      width: calc(#{get-breakpoints('xs')} / 4 * 3 - #{$grid-gutter-width})
      @include media-breakpoint-up(sm)
        width: calc(#{get-breakpoints('sm')} / 4 * 3 - #{$grid-gutter-width})
      @include media-breakpoint-up(md)
        width: calc(#{get-breakpoints('md')} / 6 * 5 - #{$grid-gutter-width})
      @include media-breakpoint-up(lg)
        width: calc(#{get-breakpoints('lg')} / 12 * 10 - #{$grid-gutter-width})
      @include media-breakpoint-up(xl)
        width: calc(#{get-breakpoints('xl')} / 12 * 10 - #{$grid-gutter-width})
      &.HorizontalShowcase__item--moved
        margin-left: calc(-#{get-breakpoints('xs')} / 4 * 3)
        @include media-breakpoint-up(sm)
          margin-left: calc(-#{get-breakpoints('sm')} / 4 * 3)
        @include media-breakpoint-up(md)
          margin-left: calc(-#{get-breakpoints('md')} / 6 * 5)
        @include media-breakpoint-up(lg)
          margin-left: calc(-#{get-breakpoints('lg')} / 12 * 10)
        @include media-breakpoint-up(xl)
          margin-left: calc(-#{get-breakpoints('xl')} / 12 * 10)
    &--L
      @include media-breakpoint-up(lg)
        width: calc(#{get-breakpoints('lg')} / 13 * 6 - #{$grid-gutter-width})
      @include media-breakpoint-up(xl)
        width: calc(#{get-breakpoints('xl')} / 13 * 6 - #{$grid-gutter-width})
      &.HorizontalShowcase__item--moved
        @include media-breakpoint-up(lg)
          margin-left: calc(-#{get-breakpoints('lg')} / 13 * 6)
        @include media-breakpoint-up(xl)
          margin-left: calc(-#{get-breakpoints('xl')} / 13 * 6)
    &--M
      @include media-breakpoint-up(lg)
        width: calc(#{get-breakpoints('lg')} / 13 * 4 - #{$grid-gutter-width})
      @include media-breakpoint-up(xl)
        width: calc(#{get-breakpoints('xl')} / 13 * 4 - #{$grid-gutter-width})
      &.HorizontalShowcase__item--moved
        @include media-breakpoint-up(lg)
          margin-left: calc(-#{get-breakpoints('lg')} / 13 * 4)
        @include media-breakpoint-up(xl)
          margin-left: calc(-#{get-breakpoints('xl')} / 13 * 4)
    &--S
      @include media-breakpoint-up(lg)
        width: calc(#{get-breakpoints('lg')} / 13 * 3 - #{$grid-gutter-width})
      @include media-breakpoint-up(xl)
        width: calc(#{get-breakpoints('xl')} / 13 * 3 - #{$grid-gutter-width})
      &.HorizontalShowcase__item--moved
        @include media-breakpoint-up(lg)
          margin-left: calc(-#{get-breakpoints('lg')} / 13 * 3)
        @include media-breakpoint-up(xl)
          margin-left: calc(-#{get-breakpoints('xl')} / 13 * 3)

.SpektCard
  cursor: pointer
  margin-right: $grid-gutter-width
  transition: margin-left $menu-transition
  &:not(&--Program)
    background-color: $background
  @extend .d-flex
  @extend .flex-column
  @include media-breakpoint-down(md)
    width: calc(#{get-breakpoints('md')} / 7 * 3 - #{$grid-gutter-width})
  @include media-breakpoint-down(sm)
    width: calc(#{get-breakpoints('sm')} / 5 * 4 - #{$grid-gutter-width})
  @include media-breakpoint-down(xs)
    width: calc(#{get-breakpoints('xs')} / 5 * 4 - #{$grid-gutter-width})
  &__name
    font-weight: bold
    margin-bottom: rem(7)
    hyphens: auto
    @include media-breakpoint-up(lg)
      padding-right: 17%
    .HorizontalShowcase__item--L &
      @include media-breakpoint-up(lg)
        @include font-params(24, 28)
    @include font-params(20, 24)
    @include media-breakpoint-down(md)
      margin-bottom: rem(2)
    @include media-breakpoint-down(sm)
      @include font-params(16, 20)
    .SpektCard:hover &
      text-decoration: underline
      text-underline-offset: .25rem
      text-decoration-thickness: from-font
  &__makers
    margin-bottom: rem(13)
    @include media-breakpoint-down(sm)
      margin-bottom: rem(14)
    &__item
      display: inline-block
      @extend .font-spectral
      @include font-params(22, 24)
      .HorizontalShowcase__item--L &
        @include media-breakpoint-up(lg)
          @include font-params(27, 28)
      @include media-breakpoint-down(md)
        @include font-params(23, 24)
      @include media-breakpoint-down(sm)
        @include font-params(19, 20)
      .SpektCard:hover &
        text-decoration: underline
        text-underline-offset: .25rem
        text-decoration-thickness: from-font
  &__short-desc
    @extend .p--s
    margin-bottom: rem(20)
    @include media-breakpoint-down(md)
      margin-bottom: rem(24)
    *
      @extend .mb-0
  &__buttons
    @extend .d-flex
    @extend .flex-row
    @extend .mt-auto
    margin-bottom: rem(16)
    .button
      margin-right: rem(6)
      cursor: pointer !important
  &__cover
    overflow: hidden
    width: 100%
    border-radius: rem(12)
    @include media-breakpoint-down(md)
      border-radius: rem(10)
    @include media-breakpoint-down(sm)
      border-radius: rem(12)
  .HorizontalShowcase__item--L &__cover
    height: rem(356)
    @include media-breakpoint-down(lg)
      height: rem(260)
  .HorizontalShowcase__item--M &__cover,
  .HorizontalShowcase__item--S &__cover
    height: rem(232)
    @include media-breakpoint-down(lg)
      height: rem(168)
  .HorizontalShowcase__item--L &__cover,
  .HorizontalShowcase__item--M &__cover,
  .HorizontalShowcase__item--S &__cover
    @include media-breakpoint-down(md)
      height: rem(176)
    @include media-breakpoint-down(sm)
      height: rem(260)
    @include media-breakpoint-down(xs)
      height: calc(((100vw - #{$grid-gutter-width} * 5) / 5 * 4 + #{$grid-gutter-width} * 3) / 236 * 144)
  &--Program
    @extend .mr-0
    @extend .w-100
    @extend .h-100
    @extend .pt-xxs
    @extend .pt-md-0
    @extend .pb-xxs
    @extend .pb-md-0
    @include media-breakpoint-down(sm)
      border-bottom: 1px solid $line-secondary
      .Program__SpektCard-delimeter:last-of-type &
        border-bottom: none
    .SpektCard__cover
      height: rem(252)
      @include media-breakpoint-down(lg)
        height: rem(185)
      @include media-breakpoint-down(md)
        height: rem(212)
      @include media-breakpoint-down(sm)
        height: calc(#{get-breakpoints('sm') / 300 * 184})
      @include media-breakpoint-down(xs)
        height: calc((100vw - #{$grid-gutter-width}) / 300 * 184)
  &.Link--disabled
    opacity: 1
    cursor: default
    &:hover
      *
        text-decoration: none
    


.Loader
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 5000
  background-color: $white
  transition: opacity .9s
  &--transparent
    opacity: 0
  &__squircles
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: rem(300)
    height: rem(300)
    &__item
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: rem(64)
      height: rem(64)
      border-radius: rem(12)
      background-color: $online-primary
      &--0
        animation: squircles-rotate 12s linear infinite
        @keyframes squircles-rotate
          0%
            transform: translate(-50%, -50%)
          100%
            transform: translate(-50%, -50%) rotate(-180deg)
      &--1,
      &--2,
      &--3,
      &--4,
      &--5,
      &--6
        animation: squircle-rotate-scale-opacity 6s linear infinite
        @keyframes squircle-rotate-scale-opacity
          0%
            opacity: .4
          60%
            opacity: 0
            width: rem(160)
            height: rem(160)
          99%
            width: rem(64)
            height: rem(64)
            opacity: 0
      &--1
        animation-delay: -5s
        transform: translate(-50%, -50%) rotate(75deg)
      &--2
        animation-delay: -4s
        transform: translate(-50%, -50%) rotate(60deg)
      &--3
        animation-delay: -3s
        transform: translate(-50%, -50%) rotate(45deg)
      &--4
        animation-delay: -2s
        transform: translate(-50%, -50%) rotate(30deg)
      &--5
        animation-delay: -1s
        transform: translate(-50%, -50%) rotate(15deg)
      &--6
        animation-delay: 0
        transform: translate(-50%, -50%) rotate(0deg)





.Archive
  padding-top: rem(171)
  @include media-breakpoint-up(lg)
    padding-top: rem(173)
  @include media-breakpoint-down(md)
    padding-top: rem(133)
  @include media-breakpoint-down(sm)
    padding-top: rem(112)
  &__pink-block
    @extend .pink-block
    @extend .d-flex
    @extend .flex-column
    @extend .justify-content-start
    @extend .h-100
    padding: rem(27) rem(30) rem(41)
    margin-bottom: rem(24)
    @include media-breakpoint-down(lg)
      padding: rem(23) rem(20) rem(41)
    @include media-breakpoint-down(md)
      padding: rem(23) rem(20) rem(33)
    @include media-breakpoint-down(sm)
      padding: rem(19) rem(16) rem(23)
    &__number
      @extend .font-spectral
      @include font-params(90, 90)
      margin-bottom: rem(14)
      @include media-breakpoint-down(md)
        margin-bottom: rem(26)
      @include media-breakpoint-down(sm)
        margin-bottom: rem(8)
  &__links
    @extend .d-flex
    @extend .align-items-end
    @extend .ml-0
    @extend .ml-md-m
    @include media-breakpoint-down(sm)
      margin-top: rem(15)
    &__item
      margin-right: rem(30)
      @extend .p--arrow
      @extend .p--arrow--up-right

        

.Editor
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, .25)
  position: fixed
  top: 0
  left: 0




.Spekt
  &__cover-container
    position: relative
    @extend .w-100
    overflow: hidden
    border-radius: rem(12)
    @include media-breakpoint-down(md)
      border-radius: rem(10)
    @include media-breakpoint-down(sm)
      border-radius: rem(8)
    &:not(&--video)
      height: 100%
    &--video
      padding-top: calc(240 / 426 * 100%)
    &__video
      @extend .abs-cover
      border: none
      top: 0
      left: 0
  &__show
    @extend .d-flex
    @extend .flex-column
    @extend .flex-md-row
    border-bottom: $border
    &:last-of-type
      border-bottom: none
    padding-top: rem(18)
    padding-bottom: rem(18)
    @include media-breakpoint-down(sm)
      padding-top: rem(17)
      padding-bottom: rem(23)
    &__date-time
      @extend .pr-md-1
      width: 50%
      @include media-breakpoint-down(sm)
        margin-bottom: rem(11)
    &__line
      @extend .pl-md-1
      width: 20%
      @include media-breakpoint-down(sm)
        margin-bottom: rem(13)
    &__buy
      @extend .button
      @extend .ml-auto
      border: none
      text-align: right
      text-decoration: underline
      text-underline-offset: .25rem
      text-decoration-thickness: from-font
      @include media-breakpoint-down(sm)
        text-align: left
      cursor: pointer
      &:hover
        text-decoration: none
        text-underline-offset: .25rem
        text-decoration-thickness: from-font
      &.Link--disabled
        cursor: not-allowed
        opacity: 1
        color: $button-disabled-fill
        &:hover
          text-decoration: none
    &__date-time,
    &__line,
    &__buy
      @include media-breakpoint-down(sm)
        width: 100%





.Schedule
  &__filter
    @extend .d-flex
    @extend .flex-column
    @extend .pb-lg-m
    position: -webkit-sticky
    position: sticky
    top: rem(43)
    &__p
      @extend .p
      @extend .p--s
      margin-bottom: rem(11)
      @include media-breakpoint-down(md)
        margin-bottom: rem(8)
    &__button
      letter-spacing: 0
      margin-right: rem(10)
      margin-bottom: rem(12)
      padding: rem(4) rem(16) rem(8)
      text-transform: lowercase
      cursor: pointer !important
      border-radius: rem(100)
      @include font-params(20, 28)
      @include media-breakpoint-down(md)
        @include font-params(16, 24)
        margin-bottom: rem(8)
      @include media-breakpoint-down(xs)
        @include font-params(14, 20)
  &__mobile-filter-container
    border: $border
    border-radius: rem(10)
    background-color: $white
    padding: rem(26) rem(24) rem(38)
    @include media-breakpoint-down(xs)
      padding: rem(22) rem(20) rem(38)
  &__load-prev
    color: rgba(0, 0, 0, .6)
    cursor: pointer
    @extend .w-100
    @extend .p
    @extend .p--l
    @extend .d-flex
    @extend .flex-row
    @extend .justify-content-center
    border-top: 1px solid $button-disabled-fill
    border-bottom: 1px solid $button-disabled-fill
    padding: rem(15) 0 rem(16)
    margin-bottom: rem(24)
    @include media-breakpoint-down(lg)
      padding: rem(10) 0 rem(12)
    @include media-breakpoint-down(md)
      padding: rem(13) 0 rem(13)
    @include media-breakpoint-down(sm)
      padding: rem(8) 0 rem(10)
      margin-bottom: rem(16)
    &::after
      content: ''
      width: rem(28)
      height: rem(28)
      @extend .def-img
      background-image: url(./img/arrow-up-gray.svg)
      @include media-breakpoint-down(md)
        width: rem(24)
        height: rem(24)
      @include media-breakpoint-down(sm)
        width: rem(20)
        height: rem(20)
    .FormattedMessage
      position: relative
  &__day
    @extend .d-flex
    @extend .flex-column
    @extend .mb-m
    @extend .mb-md-l
    // border-bottom: 1px solid $line-primary
    &__show
      @extend .row
      @extend .d-flex
      @extend .flex-column
      @extend .flex-md-row
      @extend .py-xs
      &:not(&:last-of-type)
        position: relative
        &::after
          content: ''
          position: absolute
          bottom: 0
          left: $grid-gutter-width / 2
          width: calc(100% - #{$grid-gutter-width})
          height: rem(1)
          background-color: $line-secondary
      &__info
        @extend .col-4
        @extend .col-md-3
        @extend .col-lg-6
        @extend .d-flex
        @extend .flex-column
        cursor: pointer
        &:hover
          .h3, .p:not(.p--s)
            text-decoration: underline
            text-underline-offset: .25rem
            text-decoration-thickness: from-font
        &.Link--disabled
          opacity: 1 !important
          cursor: default
          *
            text-decoration: none !important
      &__button
        @extend .button
        @extend .button--main
        @extend .pt-0
        width: 100%
        height: rem(48)
        float: right
        @include font-params(18, 45)
        @include media-breakpoint-down(md)
          @include font-params(16, 45)
          width: 75%
        @include media-breakpoint-down(sm)
          @include font-params(14, 38)
          width: 100%
          height: rem(40)
        &.Link--disabled
          opacity: 1
          @extend .button--disabled



.Program
  @extend .opened-header
  &__SpektCard-delimeter
    @extend .col-4
    @extend .col-md-3
    @extend .col-lg-4
    @extend .mb-0
    @extend .mb-md-m
    @include media-breakpoint-up(md)
      &:nth-of-type(2n - 1)
        border-right: 1px solid $line-secondary
    @include media-breakpoint-up(lg)
      &:nth-of-type(2n - 1)
        border-right: none
      &:nth-of-type(3n - 2),
      &:nth-of-type(3n - 1)
        border-right: 1px solid $line-secondary
    &:last-of-type
      border-right: none

      
.About
  @extend .opened-header
  &__Img
    border-radius: rem(12)
    overflow: hidden
    height: calc(((#{get-breakpoints('xs')} - #{$grid-gutter-width} * 5) / 4 * 3.3 + #{$grid-gutter-width} * 3) / 260 * 156)
    @include media-breakpoint-up(sm)
      height: calc(((#{get-breakpoints('sm')} - #{$grid-gutter-width} * 7) / 6 * 5 + #{$grid-gutter-width} * 4) / 260 * 156)
    @include media-breakpoint-up(md)
      height: rem(360)
    @include media-breakpoint-up(lg)
      height: rem(464)
    @include media-breakpoint-up(xl)
      height: rem(632)
  &__person
    @extend .col-2
    @extend .col-lg-3
    @extend .mb-xs
    @extend .mb-md-l
    &__avatar
      @extend .mb-xxs
      @extend .mb-md-xs
      border-radius: 100%
      overflow: hidden
      position: relative
      width: calc(100% - #{$grid-gutter-width})
      padding-top: calc(100% - #{$grid-gutter-width})
      @include media-breakpoint-up(md)
        width: calc(100% - #{$grid-gutter-width} * 1.5)
        padding-top: calc(100% - #{$grid-gutter-width} * 1.5)
      @include media-breakpoint-up(xl)
        width: calc((100% - #{$grid-gutter-width} * 2) / 3 * 2 + #{$grid-gutter-width})
        padding-top: calc((100% - #{$grid-gutter-width} * 2) / 3 * 2 + #{$grid-gutter-width})
      .Img
        @extend .abs-cover
    &__name
      @extend .p
      @extend .p--l
    &__title
      @extend .p
      @extend .p--xl
      @extend .font-spectral


.QandA
  @extend .opened-header
  &__Link
    @extend .h3
    white-space: nowrap
    @include media-breakpoint-up(md)
      margin-bottom: rem(16)
    @include media-breakpoint-down(md)
      margin-right: rem(40)
    @include media-breakpoint-down(sm)
      margin-right: rem(16)
    &--active
      position: relative
      &::after
        content: ''
        position: absolute
        left: 0
        bottom: 0
        width: 100%
        height: rem(1)
        background-color: $black
      
.Accreditation,
.Contacts,
.Projects,
.Reviews
  @extend .opened-header


.Subscribe
  background-color: $black-action
  *
    background-color: $black-action
    color: $white
  &__content
    width: 100%
    overflow: hidden
    position: relative
    &__container
      position: relative
      width: 300%
      left: rem(-55)
      top: 0
  &__desc
    @extend .p
    @extend .p--l
    @extend .mr-2
    width: rem(162)
    @include media-breakpoint-up(sm)
      width: rem(204)
    @include media-breakpoint-up(lg)
      width: rem(246)
  &__h1
    @extend .font-spectral
    @extend .mr-4
    @include font-params(142, 120)
    @include media-breakpoint-down(md)
      @include font-params(80, 80)
    @include media-breakpoint-down(xs)
      display: none
  &__input,
  &__submit
    border: 3px solid $white
    outline: none
    width: 100%
    height: rem(48)
    @include font-params(24, 32)
    @include media-breakpoint-up(md)
      height: rem(66)
      @include font-params(28, 58)
    @include media-breakpoint-up(lg)
      height: rem(88)
      @include font-params(46, 46)
  &__input
    padding-left: rem(20)
    @include media-breakpoint-down(md)
      text-transform: lowercase
      text-align: center
    &--Error
      border-color: $danger
      *
        color: $danger
    &--Done
      border-color: $online-primary
      *
        color: $online-primary

.FestivalPass
  background-color: $online-primary
  &--Home
    cursor: pointer
    *
      background-color: $online-primary
      color: $black-primary
    .FormattedMessage
      background-color: transparent
    &__content
      width: 100%
      overflow: hidden
      position: relative
      &__container
        @extend .d-flex
        @extend .flex-row
        @extend .align-items-center
        position: relative
        width: 300%
        left: rem(-55)
        top: 0
        &--1
          @include media-breakpoint-down(md)
            left: -100px !important
        &--2
          @include media-breakpoint-down(md)
            left: -100px !important
        &--3
          @include media-breakpoint-down(sm)
            display: none !important
    &__desc
      @extend .p
      @extend .p--l
      @extend .mr-2
      width: rem(162)
      @include media-breakpoint-up(sm)
        width: rem(204)
      @include media-breakpoint-up(lg)
        width: rem(246)
    &__h1
      white-space: nowrap
      @extend .mr-5
      @include font-params(120, 120)
      @include media-breakpoint-down(md)
        @include font-params(72, 80)
      @include media-breakpoint-down(xs)
        @include font-params(32, 48)
    &__buy
      @extend .mr-5
      border: 3px solid $black-primary
      outline: none
      width: 30vw
      text-align: center
      @include font-params(24, 44)
      height: rem(48)
      @include media-breakpoint-up(md)
        transform: translateY(10px)
        height: rem(66)
        @include font-params(28, 56)
      @include media-breakpoint-up(lg)
        height: rem(88)
        @include font-params(46, 78)
      .FestivalPass:hover &
        background-color: $black-primary
        *
          color: $online-primary
  &--Spekt
    border-radius: rem(12)
    @extend .d-flex
    @extend .flex-column
    @extend .align-items-start
    @extend .justify-content-between
    @extend .mb-3
    padding: rem(18) rem(16) rem(19)
    @include media-breakpoint-up(md)
      padding: rem(18) rem(24) rem(26)
    @include media-breakpoint-up(lg)
      padding: rem(23) rem(32) rem(25)
    &__text
      font-weight: bold
      @extend .mb-3
      &__promocode
        @extend .mx-2
        @extend .p
        @extend .p--copy
        @extend .cursor-pointer
      &__tooltip
        font-weight: normal
        @extend .d-inline-block
        @extend .p
        @extend .p--xs
        text-transform: uppercase
        color: $white
        background-color: black
        border-radius: rem(20)
        line-height: rem(24)
        padding: rem(0) rem(12)
  &--Schedule
    @extend .d-flex
    @extend .flex-column
    @extend .flex-md-row
    @extend .align-items-center
    border-radius: rem(12)
    padding: rem(14) 0 rem(20)
    margin-bottom: rem(30)
    @include media-breakpoint-up(md)
      padding: rem(16) 0
      margin-bottom: rem(42)
    @include media-breakpoint-up(lg)
      margin-bottom: rem(36)
    @include media-breakpoint-up(xl)
      margin-bottom: rem(44)
    &__h1
      @extend .text-center
      @include font-params(26, 32)
      @include media-breakpoint-up(md)
        @include font-params(32, 40)
        margin-bottom: rem(10)
      @include media-breakpoint-up(xl)
        @include font-params(46, 52)
    &__small
      @extend .font-spectral
      @extend .text-center
      @extend .text-md-left
      @include font-params(14, 16)
      @include media-breakpoint-up(md)
        @include font-params(16, 20)
      @include media-breakpoint-down(sm)
        padding: rem(7) 0 rem(15)
        width: rem(208)
        margin-left: auto
        margin-right: auto
    &__button
      @extend .mx-auto
      @extend .text-center
      cursor: pointer
      background-color: $black-primary
      color: $online-primary
      display: block
      &:hover
        color: $online-primary
      @include font-params(20, 40)
      width: rem(180)
      height: rem(44)
      @include media-breakpoint-up(md)
        @include font-params(28, 56)
        width: rem(186)
        height: rem(60)
      @include media-breakpoint-up(xl)
        @include font-params(32, 63)
        height: rem(68)





.LogoWithText
  height: rem(48)
  @include media-breakpoint-down(md)
    height: rem(40)
  @extend .d-flex
  @extend .flex-row
  @extend .align-items-center
  &__img
    height: 100%
    width: auto
    margin-right: rem(10)
  &__name
    font-family: 'Montserrat', sans-serif
    font-variant-alternates: normal
    text-transform: lowercase
    font-size: rem(20)
    white-space: nowrap
    font-weight: 800
    @include media-breakpoint-down(md)
      font-size: rem(16.66666)



.Error404
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  background-color: $background
  z-index: 1000
  @extend .d-flex
  @extend .flex-column
  @extend .align-items-center
  @extend .justify-content-center
  &__blur
    &--0
      position: absolute
      top: 0
      left: 0
      width: 100%
      @extend .d-flex
      @extend .flex-row
      @extend .justify-content-center
      @extend .pt-3
      @extend .pt-lg-xs
      filter: blur(4px)
    &--1,
    &--2,
    &--3
      @extend .h1
      @extend .text-center
    &--1
      filter: blur(6px)
      @include media-breakpoint-down(md)
        filter: blur(4px)
    &--2
      filter: blur(4px)
      @include media-breakpoint-down(md)
        filter: blur(2px)
    &--3
      filter: blur(2px)
      @include media-breakpoint-down(md)
        filter: blur(1px)



.Tickets
  &__show
    @extend .d-flex
    @extend .flex-column
    @extend .h-100
    border-radius: rem(12)
    padding: rem(21) rem(16) rem(31)
    @include media-breakpoint-up(md)
      padding: rem(22) rem(20) rem(35)
    @include media-breakpoint-up(lg)
      padding: rem(23) rem(20) rem(39)
    @include media-breakpoint-up(xl)
      padding: rem(23) rem(24) rem(39)
    &__delimeter
      @extend .w-100
      height: rem(1)
      background-color: $line-primary
      margin-bottom: rem(16)
      @include media-breakpoint-up(md)
        margin-bottom: rem(17)
      @include media-breakpoint-up(lg)
        margin-bottom: rem(20)
    &__name
      font-weight: bold
      @include font-params(16, 20)
      @include media-breakpoint-up(md)
        @include font-params(20, 24)
    &__persons
      @extend .font-spectral
      @include font-params(19, 20)
      @include media-breakpoint-up(md)
        @include font-params(22, 24)
      margin-bottom: rem(14)
    .button--program
      background-color: $line-secondary
    &:not(&--past)
      background-color: $background
    &--past
      color: $button-disabled-text
      *
        color: $button-disabled-text
      .Tickets__show__delimeter
        background-color: $button-disabled-fill
      border: 1px solid $button-disabled-text


.ShowPage
  &__ticket
    @extend .h-100
    border: 1px solid $black-primary
    border-radius: rem(16)
    padding: rem(25) rem(24) rem(28)
    @include media-breakpoint-up(md)
      padding: rem(26) rem(24) rem(34)
    @include media-breakpoint-up(lg)
      padding: rem(27) rem(24) rem(40)
    &__link
      @extend .p
      @extend .p--l
      @extend .cursor-pointer
      @extend .mb-2
      text-decoration: underline
      text-underline-offset: 0
      text-decoration-thickness: from-font
  &__map-container
    width: 100%
    height: rem(300)
    border-radius: rem(12)
    overflow: hidden



.KeyVisual
  @extend .w-100
  overflow: hidden
  &__container
    position: relative
    top: 0
    @extend .d-flex
    @extend .flex-row
    transition: left .05
  &__block
    width: rem(684)
    min-width: rem(684)
    height: rem(684)
    @include media-breakpoint-down(lg)
      width: rem(512)
      min-width: rem(512)
      height: rem(512)
    @include media-breakpoint-down(md)
      width: rem(460)
      min-width: rem(460)
      height: rem(460)
    @include media-breakpoint-down(sm)
      width: rem(320)
      min-width: rem(320)
      height: rem(320)
    &__name
      @extend .p
      @extend .p--m
      @extend .text-uppercase
      @extend .mb-4
    &__dates
      @extend .font-spectral
      @include font-params(18, 20)
      @include media-breakpoint-down(sm)
        @include font-params(13, 14)
    &__container
      background-color: $background
      width: 100%
      height: 100%
      @extend .d-flex
      @extend .flex-column
      @extend .p-xxs
  .LogoWithText
    &__img
      width: rem(87)
      height: rem(87)
      @include media-breakpoint-down(md)
        width: rem(71)
        height: rem(71)
      @include media-breakpoint-down(sm)
        width: rem(51)
        height: rem(51)
    &__name
      font-size: rem(40)
      @include media-breakpoint-down(md)
        font-size: rem(34)
      @include media-breakpoint-down(sm)
        font-size: rem(24)



