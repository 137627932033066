@import './vars.sass'
@import './fonts.sass'
@import './modificators.sass'


.close-popup-radario__title
  color: white

.button
  @extend .no-select
  cursor: pointer
  outline: none
  border: none
  background-color: transparent
  color: $black-primary
  border: $border
  border-radius: rem(7)
  text-align: center
  display: block
  white-space: nowrap
  &--navigation
    @extend .button
    border-color: rgba(0, 0, 0, 0)
    border-radius: rem(12)
    padding: rem(11) rem(16) rem(13)
    border-color: rgba(0, 0, 0, 0)
    transition: border-color $menu-transition
    &:hover,
    &--hover
      border-color: $line-primary
  &[disabled],
  &--disabled
    cursor: not-allowed
    background-color: transparent !important
    border-color: $button-disabled-fill !important
    color: $button-disabled-text !important
  &--main,
  &--secondary
    @extend .button
    width: 100%
    height: rem(56)
    line-height: rem(24)
    font-size: rem(18)
    padding-top: rem(14)
    border-radius: rem(12)
    @include media-breakpoint-down(md)
      height: rem(48)
      font-size: rem(16)
      padding-top: rem(10)
      border-radius: rem(10)
    @include media-breakpoint-down(xs)
      height: rem(40)
      line-height: rem(16)
      font-size: rem(14)
      padding-top: rem(11)
      border-radius: rem(8)
    transition: background-color $menu-transition
  &--main
    color: $white !important
    background-color: $black-primary
    &:hover
      background-color: $black-action
    &--2
      @extend .button--main
  &--secondary
    @extend .button
    transition: background-color $menu-transition, color $menu-transition
    &--active,
    &:hover
      color: $white !important
      background-color: $black-primary
  &--left,
  &--right
    @extend .button--secondary
  &--online,
  &--offline,
  &--program,
  &--selectable
    letter-spacing: .08rem
  &--online,
  &--offline,
  &--age,
  &--program,
  &--selectable
    cursor: default !important
    text-transform: uppercase
    padding: rem(4) rem(8)
    border: none
    border-radius: rem(12)
    @include font-params(11, 16)
    @include media-breakpoint-down(md)
      @include font-params(9, 16)
  &--online
    background-color: $online-secondary
  &--offline
    background-color: $offline-secondary
  &--age
    background-color: $line-secondary
  &--program
    background-color: $button-program
  &--selectable
    background-color: $white !important
    box-shadow: inset 0 0 0 1px $button-disabled-fill
  &--arrow
    @extend .button
    @extend .button--secondary
    width: rem(96)
    height: rem(40)
    @include media-breakpoint-down(md)
      width: rem(64)
      height: rem(32)
    @include media-breakpoint-down(sm)
      width: calc(50% - #{rem(8)})
      height: rem(36)
    position: relative
    svg
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      width: rem(28)
      height: rem(28)
      @include media-breakpoint-down(md)
        width: rem(24)
        height: rem(24)
      @include media-breakpoint-down(sm)
        width: rem(28)
        height: rem(28)
      rect, path
        transition: fill $menu-transition
    &:hover
      svg
        rect, path
          fill: $white
    &[disabled]
      svg
        rect, path
          fill: $button-disabled-fill
    &--left
      svg
        transform: translate(-50%, -50%) rotate(180deg)

      
.pink-block
  border-radius: rem(12)
  background-color: $background


.delimeter
  width: 100%
  height: rem(1)
  background-color: $line-secondary


.Link
  color: $black-primary
  &:hover
    text-decoration: none
    color: $black-primary
  &--active
    cursor: default
  &--disabled
    opacity: .5
    cursor: not-allowed


.FormattedMessage
  display: inline
  @extend .inherit-all




.Img
  width: 100%
  transition: opacity $menu-transition
  &--hidden
    opacity: 0
  &__img
    width: 100%
    &--portrait
      width: 100%
      height: auto
    &--landscape
      width: auto
      height: 100%




.Dropdown
  @extend .d-flex
  @extend .flex-column
  @extend .align-items-center
  border-top: $border
  .Archive &:first-of-type
    margin-top: rem(93)
    @include media-breakpoint-down(lg)
      margin-top: rem(103)
    @include media-breakpoint-down(md)
      margin-top: rem(63)
    @include media-breakpoint-down(sm)
      margin-top: rem(40)
  &:last-of-type
    border-bottom: $border
  &__title
    width: 100%
    cursor: pointer
    position: relative
    display: inline-block
    padding: rem(15) 0 rem(19)
    @extend .d-flex
    &__text
      width: 100%
      @extend .flex-grow-1
      @extend .no-select
      &::after
        content: ''
        position: absolute
        right: rem(32)
        top: 50%
        @include media-breakpoint-down(sm)
          top: 35%
        width: rem(32)
        height: rem(32)
        @extend .def-img
        background-image: url(./img/dropdown__arrow.svg)
        transform: translateY(-50%) rotate(-90deg)
        transition: transform $menu-transition
        .Dropdown--opened &
          transform: translateY(-50%) rotate(0deg)
  &__content
    width: 100%
    transition: height $menu-transition
    overflow: hidden
    &__container
      padding: rem(63) 0 rem(48)
      @include media-breakpoint-down(md)
        padding: rem(32) 0 rem(48)
      @include media-breakpoint-down(sm)
        padding: rem(21) 0 rem(31)
      width: 100%
      @extend .d-flex
      @extend .flex-column
  &--spekt
    border: $border
    border-radius: rem(12)
    @include media-breakpoint-down(sm)
      border-radius: rem(8)
    .Dropdown__content
      padding: rem(0) rem(24) rem(0)
      @include media-breakpoint-down(md)
        padding: rem(0) rem(21) rem(0)
      @include media-breakpoint-down(sm)
        padding: rem(0) rem(16) rem(0)
      &__container
        padding: rem(7) rem(0) rem(41)
        @include media-breakpoint-down(md)
          padding: rem(6) rem(0) rem(42)
        @include media-breakpoint-down(sm)
          padding: rem(25) rem(0) rem(43)
    .Dropdown__title
      padding: rem(23) rem(24) rem(29)
      @include media-breakpoint-down(md)
        padding: rem(23) rem(21) rem(27)
      @include media-breakpoint-down(sm)
        padding: rem(13) rem(16) rem(15)
      &:hover
        .Dropdown__title__text__Plus
          background-color: black
          line
            stroke: $white
      &__text
        position: relative
        &::after
          @extend .d-none
        &__Plus
          position: absolute
          top: 50%
          right: 0
          width: rem(32)
          height: rem(32)
          @include media-breakpoint-down(sm)
            width: rem(24)
            height: rem(24)
          transform: translateY(-50%) rotate(0deg)
          border-radius: rem(100)
          // .Dropdown--opened &
          transition: transform $menu-transition, background-color $menu-transition
          line
            transition: stroke $menu-transition
  &--opened
    .Dropdown__title__text__Plus
      transform: translateY(-50%) rotate(45deg)





.RangePicker
  @extend .mb-s
  @extend .mb-lg-s
  @extend .d-inline-flex
  @extend .flex-row
  position: relative
  width: 260px
  @include media-breakpoint-down(md)
    width: 220px
  &__dash
    @extend .mx-1
    line-height: rem(40)
    @include media-breakpoint-down(md)
      line-height: rem(36)
      
.DatePicker
  &__input
    @extend .d-inline-block
    @extend .p
    @extend .p--l
    outline: none
    border: $border
    border-color: $line-secondary
    border-radius: rem(100)
    text-align: center
    width: rem(120)
    height: rem(40)
    line-height: rem(40)
    @include media-breakpoint-down(md)
      width: rem(100)
      height: rem(36)
      line-height: rem(36)
    &--focused
      border-color: $line-primary
  &__calendar
    width: 100%
    position: absolute
    left: 0
    bottom: rem(50)
    @extend .d-flex
    @extend .flex-column
    border: $border
    border-radius: rem(10)
    background-color: $white
    z-index: 2000
    &__header
      @extend .d-flex
      @extend .flex-row
      @extend .justify-content-around
      @extend .py-2
      &__year,
      &__month,
      &__day
        @extend .button--secondary
        border-radius: rem(100)
        width: auto
        line-height: 1
        height: rem(30)
        padding: rem(4) rem(14) rem(4)
        &--active
          @extend .button--secondary--active
    &__body
      @extend .d-flex
      @extend .flex-row
      @extend .flex-wrap
      @extend .justify-content-around
      @extend .mb-2
      &__year,
      &__month,
      &__day
        cursor: pointer
        text-align: center
        @extend .py-1
        @extend .my-1
        border-radius: rem(100)
        &:hover
          box-shadow: inset 0 0 0 1px $line-primary
        &--active
          color: $white
          background-color: $black !important
        &--today
          background-color: $online-secondary
        &--another-month
          opacity: .5
        &--disabled
          cursor: not-allowed
          color: rgba(0, 0, 0, .5)
          &:hover
            box-shadow: none !important
      &__year,
      &__month
        width: 34%
      &__day
        width: calc(100% / 7.9)
        // height: rem(26)
        // line-height: rem(26)
        text-align: center


@mixin avatar-params($size, $margin)
  .CuratorsAvatars__item
    width: $size
    height: $size
    &:not(&:last-of-type)
      margin-right: $margin

.CuratorsAvatars
  @extend .d-flex
  @extend .flex-row
  &--S
    @include avatar-params(rem(80), rem(-28))
  &--M
    @include avatar-params(rem(140), rem(-32))
  &--L
    @include avatar-params(rem(192), rem(-27))
  &__item
    position: relative
    border-radius: rem(200)
    overflow: hidden
    &__avatar
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%


  
.TextDropdown
  @extend .row
  @extend .d-flex
  @extend .flex-column
  @extend .flex-lg-row
  @extend .flex-md-column
  @extend .flex-xl-row
  margin-bottom: rem(-1)
  padding: rem(41) 0 rem(40)
  @include media-breakpoint-down(md)
    padding: rem(27) 0 rem(31)
  @include media-breakpoint-down(sm)
    padding: rem(29) 0 rem(26)
  position: relative
  &::before,
  &::after
    content: ''
    position: absolute
    left: rem(12)
    width: calc(100% - #{$grid-gutter-width})
    height: rem(1)
    background-color: $black
  &::before
    top: 0
  &::after
    bottom: 0
  &__content
    @extend .col-4
    @extend .col-md-6
    @extend .col-lg-8
    @extend .order-2
    position: relative
    padding-bottom: rem(49)
    @include media-breakpoint-down(md)
      padding-bottom: rem(43)
    @include media-breakpoint-down(sm)
      padding-bottom: rem(36)
    &__container
      width: calc(100% - #{$grid-gutter-width})
      position: relative
      overflow: hidden
      transition: height $menu-transition
      &__preview,
      &__full
        position: absolute
        top: 0
        left: 0
      &__preview
        @extend .p
        @extend .p--xxl
        background-color: $white
        opacity: 1
        transition: opacity $menu-transition
        .TextDropdown--opened &
          opacity: 0
      &__full
        @extend .p
        @extend .p--l
  &__toggle
    position: absolute
    bottom: 0
    left: $grid-gutter-width / 2
    cursor: pointer
    @extend .p
    @extend .p--l
    // &::after
    //   content: ''
    //   position: absolute
    //   @extend .def-img
    //   background-image: url(./img/arrow-right-inline.svg)
    //   width: rem(28)
    //   height: rem(28)
    //   right: rem(-36)
    //   @include media-breakpoint-down(md)
    //     width: rem(24)
    //     height: rem(24)
    //     right: rem(-32)
    //   @include media-breakpoint-down(sm)
    //     width: rem(20)
    //     height: rem(20)
    //     right: rem(-28)
  &__additional-content
    @extend .col-4
    @extend .col-md-6
    @extend .col-lg-4
    @extend .order-1
    @extend .order-lg-2

