@import '~bootstrap/scss/bootstrap.scss'

@import url('https://fonts.googleapis.com/css2?family=Spectral:ital,wght@1,300&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap')


@font-face
  font-family: 'Formular'
  src: url('./fonts/Formular/Formular.ttf') format('truetype')
  font-weight: normal
@font-face
  font-family: 'Formular'
  src: url('./fonts/Formular/Formular-Bold.ttf') format('truetype')
  font-weight: bold


.font-spectral
  font-family: 'Spectral', serif
  font-style: italic
  font-weight: 300

.underline
  width: 100%
  position: relative
  margin-bottom: rem(16 + 28) !important
  &::after
    content: '' 
    position: absolute
    bottom: rem(-16)
    left: 0
    width: 100%
    height: rem(1)
    background-color: $black-primary


@mixin font-params($font-size, $line-height)
  font-size: rem($font-size) !important
  line-height: rem($line-height) !important


.h1, .h2, .h3
  font-weight: normal
  &:not(&.font-spectral),
  &:not(&.font-weight-normal)
    font-weight: bold !important
  &--underline
    @extend .underline
.h1
  @include font-params(45, 56)
  @include media-breakpoint-down(lg)
    @include font-params(36, 44)
  @include media-breakpoint-down(xs)
    @include font-params(28, 36)
  &--italic
    @extend .font-spectral
    font-size: rem(51)
    @include media-breakpoint-down(lg)
      font-size: rem(41)
    @include media-breakpoint-down(xs)
      font-size: rem(31)
  &--xl
    @include font-params(64, 64)
    @include media-breakpoint-down(xs)
      @include font-params(48, 48)

.h2
  @include font-params(32, 40)
  margin-bottom: rem(26)
  @include media-breakpoint-down(md)
    @include font-params(26, 32)
    margin-bottom: rem(21)
  @include media-breakpoint-down(xs)
    @include font-params(22, 28)
    margin-bottom: rem(18)
.h3
  @include font-params(20, 28)
  @include media-breakpoint-down(md)
    @include font-params(16, 24)
  @include media-breakpoint-down(xs)
    @include font-params(14, 20)


.p
  &--xxxxl
    @include font-params(36, 40)
    @include media-breakpoint-down(md)
      @include font-params(30, 32)
    @include media-breakpoint-down(xs)
      @include font-params(25, 28)
  &--xxxl
    @include font-params(32, 40)
    @include media-breakpoint-down(lg)
      @include font-params(28, 40)
    @include media-breakpoint-down(md)
      @include font-params(26, 32)
    @include media-breakpoint-down(xs)
      @include font-params(18, 24)
  &--xxl
    @include font-params(26, 32)
    @include media-breakpoint-down(md)
      @include font-params(20, 28)
    @include media-breakpoint-down(xs)
      @include font-params(18, 24)
  &--xl
    @include font-params(23, 28)
    @include media-breakpoint-down(md)
      @include font-params(19, 24)
    @include media-breakpoint-down(xs)
      @include font-params(16, 20)
  &--l
    @include font-params(20, 28)
    @include media-breakpoint-down(md)
      @include font-params(16, 24)
    @include media-breakpoint-down(xs)
      @include font-params(14, 20)
  &--m
    @include font-params(16, 24)
    &.p--arrow,
    &.p--cross
      &::before,
      &::after
        width: rem(24)
        height: rem(24)
  &--s
    @include font-params(14, 20)
    &.p--arrow,
    &.p--cross
      &::before,
      &::after
        width: rem(20)
        height: rem(20)
    @include media-breakpoint-down(md)
      @include font-params(12, 16)
      &.p--arrow,
      &.p--cross
        &::before,
        &::after
          width: rem(16)
          height: rem(16)
  &--xs
    @include font-params(12, 16)
    @include media-breakpoint-down(md)
      @include font-params(10, 12)
    @include media-breakpoint-down(xs)
      @include font-params(10, 14)
  &--xxs
    @include font-params(8, 11)
  &--arrow,
  &--cross,
  &--copy
    @extend .d-inline-flex
    @extend .align-items-end
    &::before,
    &::after
      @extend .def-img
      @extend .d-inline-block
      margin-left: rem(8)
      width: rem(28)
      height: rem(28)
      @include media-breakpoint-down(md)
        width: rem(24)
        height: rem(24)
      @include media-breakpoint-down(sm)
        width: rem(20)
        height: rem(20)

  &--arrow
    &--right
      &::after
        content: ''
        background-image: url(./img/arrow-right.svg)
        transition: transform $menu-transition
      &:hover
        &::after
          transform: translateX(5px)
    &--left
      &::before
        content: ''
        background-image: url(./img/arrow-right.svg)
        transition: transform $menu-transition
        transform: translate(-7px) rotate(180deg)
      &:hover
        &::before
          transform: translateX(-12px) rotate(180deg)
    &--up-right
      &::after
        content: ''
        background-image: url(./img/arrow-up-right.svg)
    &--down
      &::after
        content: ''
        background-image: url(./img/arrow-right.svg)
        transform: rotate(90deg)

  &--cross
    &::after
      content: ''
      background-image: url(./img/cross.svg)

  &--copy
    &::after
      content: ''
      background-image: url(./img/copy.svg)
      width: rem(20)
      height: rem(20)



.color-button-disabled-text
  color: $button-disabled-text
.color-danger
  color: $danger
.color-white
  color: $white



p
  a
    color: $black-primary
    text-decoration: underline
    &:hover
      color: $black-primary
  i
    @extend .font-spectral
      

  
blockquote
  p
    @extend .p
    @extend .p--xxl
    @extend .mb-xs
    @extend .mb-md-s
    // text-align: right
    i
      // font-weight: 700
      // width: 100%